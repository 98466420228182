.timeline {
  background-color: rgb(26, 26, 26);
  /* background: transparent; */
  padding: 6rem 0rem;
  overflow: hidden;
}

.timeline .section-title {
  color: white;
  width: 100%;
  text-align: center;
}

.timeline .date-light {
  color: var(--green);
}

/* Black icons */
.timeline svg {
  color: black;
}

/* Flipped icons (not on mobile) */
@media screen and (min-width: 1150px) {
  .timeline .flipped {
    transform: scaleX(-1);
  }
}
