.prizes {
  padding: 6rem 0rem;
  background: rgb(32, 98, 56);
  background: linear-gradient(
    130deg,
    rgba(32, 98, 56, 1) 0%,
    rgba(0, 34, 12, 1) 100%
  );
  color: white;
}

.prizes .section-title {
  width: 100%;
  text-align: center;
}

.prizes-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2rem;
  min-height: 30rem;
}
.prizes-card {
  width: 20rem;
  height: 20rem;
  transition: transform 0.3s;
}
.prizes-front {
  position: relative;
  height: 20rem;
  background-color: rgb(34, 34, 34);
  border-radius: 1rem;
}

.prizes-cash {
  position: absolute;
  text-align: right;
  bottom: 1rem;
  right: 1rem;
  font-size: 2.5rem;
  font-weight: bold;
  transition: 0.3s;
  color: rgb(196, 196, 196);
}

.prizes-card,
.prizes-img-right,
.prizes-img-left {
  filter: drop-shadow(0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.5));
}

.prizes-img-left {
  position: absolute;
  bottom: -1rem;
  z-index: 1;
  transition: 0.3s;
  width: 16rem;
  left: -1rem;
}

.prizes-img-right {
  position: absolute;
  width: 16rem;
  right: -3rem;
  bottom: -0.25rem;
  z-index: 1;
  transition: 0.3s;
}

.prizes-card-gold .prizes-img-left {
  left: -4rem;
  bottom: -0.5rem;
}

.prizes-position {
  font-size: 6rem;
  text-align: right;
  padding: 1rem;
  transition: 0.3s color;
}

.prizes-back {
  border-radius: 0rem 0rem 1rem 1rem;
  text-align: center;
  font-size: 1.25rem;
  overflow: hidden;
  height: 0rem;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.prizes-card-gold .prizes-back {
  background-color: rgb(109, 93, 0);
}
.prizes-card-silver .prizes-back {
  background-color: rgb(105, 105, 105);
}
.prizes-card-bronze .prizes-back {
  background-color: rgb(88, 61, 39);
}

.prizes-back p {
  position: relative;
  bottom: 0;
}

@media screen and (min-width: 992px) {
  .prizes {
    padding-top: 8rem;
  }
  .prizes-card:hover {
    filter: drop-shadow(0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.8));
  }
  .prizes-card:hover .prizes-front {
    border-radius: 1rem 1rem 0rem 0rem;
  }
  .prizes-card:hover .prizes-img-left {
    width: 16.25rem;
    left: -1.5rem;
    bottom: -1.25rem;
  }
  .prizes-card-gold:hover .prizes-img-left {
    width: 16.25rem;
    left: -4.5rem;
    bottom: -0.75rem;
  }
  .prizes-card:hover .prizes-img-right {
    width: 16.5rem;
    right: -3.5rem;
    bottom: -0.75rem;
  }
  .prizes-card:hover .prizes-cash {
    bottom: 0.75rem;
    right: 0.75rem;
  }

  .prizes-front:hover + .prizes-back,
  .prizes-back:hover {
    padding-top: 1rem;
    height: 8rem;
  }
  .prizes-card-gold:hover .prizes-position {
    color: rgb(248, 211, 0);
  }
  .prizes-card-silver:hover .prizes-position {
    color: rgb(180, 180, 180);
  }
  .prizes-card-bronze:hover .prizes-position {
    color: rgb(199, 143, 98);
  }

  .prizes-card-silver {
    transform: scale(0.9);
  }
  .prizes-card-bronze {
    transform: scale(0.8);
  }

  /* Card size on hover*/
  .prizes-card-gold:hover {
    transform: scale(1.02);
  }
  .prizes-card-silver:hover {
    transform: scale(0.95);
  }
  .prizes-card-bronze:hover {
    transform: scale(0.85);
  }
}

@media screen and (max-width: 991px) {
  .prizes-cards {
    flex-direction: column;
    align-items: center;
  }
  .prizes-card {
    height: 30rem;
  }
  .prizes-back {
    padding-top: 1rem;
    height: 8rem;
  }
  .prizes-card-gold .prizes-position {
    color: rgb(248, 211, 0);
  }
  .prizes-card-silver .prizes-position {
    color: rgb(180, 180, 180);
  }
  .prizes-card-bronze .prizes-position {
    color: rgb(199, 143, 98);
  }
  .prizes-card-gold {
    order: 1;
  }
  .prizes-card-silver {
    order: 2;
  }
  .prizes-card-bronze {
    order: 3;
  }
}
