/* Navigation bar */
.navbar {
  font-size: 1rem;
  font-weight: bold;
  position: fixed;
  width: 100%;
  height: 5rem;
  z-index: 3;
  padding: 0.5rem 4rem;
  transition: 0.3s background-color;
}

.navbar-logo,
.navbar-logo-full {
  height: 4rem;
  cursor: pointer;
}

/* Only for larger screens */
@media screen and (min-width: 992px) {
  .navbar {
    -webkit-backdrop-filter: blur(0.5rem);
            backdrop-filter: blur(0.5rem);
  }
  /* Branding */
  .navbar-logo {
    position: relative;
    transition: 0.3s;
  }
  .navbar-logo-full {
    position: absolute;
    transition: 0.3s;
  }

  /* Logo animations */
  .navbar-light .navbar-logo-full {
    left: 4rem;
    opacity: 1;
  }

  .navbar-dark .navbar-logo-full {
    left: 3rem;
    opacity: 0;
  }

  .navbar-dark .navbar-logo {
    opacity: 1;
    left: 0;
  }

  .navbar-light .navbar-logo {
    left: 1.1rem;
    opacity: 0;
  }
}

/* Navbar Background color */
.navbar.navbar-light.bg-light {
  background-color: transparent !important;
}

.navbar.navbar-dark.bg-dark {
  background-color: rgba(22, 25, 27, 0.8) !important;
  box-shadow: 0rem 0.5rem 2rem rgba(0, 0, 0, 0.7);
}

/* Navlist Items */
.navbar .navbar-nav .nav-item .nav-link {
  padding: 0rem 3rem;
  color: white;
  cursor: pointer;
}

.navbar .navbar-nav .nav-item .nav-link.current,
.navbar .navbar-nav .nav-item .nav-link:hover {
  color: var(--green);
}

/* Navlist toggle */
.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url(/static/media/logo.849aef0e.webp);
}

.navbar .navbar-toggler .navbar-toggler-icon::after {
  content: url(/static/media/up-arrow.6cef2db9.svg);
  width: 100%;
  height: 100%;
}

/* Mobile */
@media screen and (max-width: 991px) {
  .navbar.navbar-dark.bg-dark {
    background-color: black !important;
  }

  /* Hide logo */
  .navbar .navbar-logo,
  .navbar .navbar-logo-full {
    display: none;
  }
  /* Div with links */
  .navbar .navbar-collapse {
    position: fixed;
    top: 0;
    padding: 6rem 1rem;
    background-color: rgba(0, 0, 0, 0.95);
    height: 100vh;
    min-width: 33vw;
    right: -100%;
    text-align: right;
    transition: 0.1s right;
  }
  .navbar .navbar-collapse.show {
    right: 0;
    z-index: -1;
  }
  .navbar .navbar-nav .nav-item {
    padding: 1rem 0rem;
  }
  .navbar .navbar-nav > *:not(:last-child) {
    border-bottom: 1px solid white;
  }

  /* Navlis toggle */
  .navbar .navbar-toggler {
    border: none;
    box-shadow: none;
  }
  .navbar .navbar-toggler .navbar-toggler-icon {
    background-size: cover;
    height: 2rem;
  }
  .navbar .navbar-toggler .navbar-toggler-icon::after {
    display: block;
    position: relative;
    right: 3rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-filter: invert(56%) sepia(54%) saturate(561%) hue-rotate(104deg)
      brightness(89%) contrast(94%);
            filter: invert(56%) sepia(54%) saturate(561%) hue-rotate(104deg)
      brightness(89%) contrast(94%);
    transition: 0.3s;
  }
  .navbar .navbar-toggler.collapsed .navbar-toggler-icon::after {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-filter: invert(100%);
            filter: invert(100%);
  }
}

.countdown {
  height: 40rem;
  min-height: 100vh;
  background-image: url(/static/media/bg.1316b79e.webp);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgb(0, 0, 0)),
    url(/static/media/bg.1316b79e.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  font-size: 1rem;
}

.countdown > .container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.countdown-timer {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  text-align: center;
}

.countdown-timer > * {
  padding: 1rem;
  font-size: 1rem;
}

.countdown-number {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.countdown-number-num {
  font-size: 5rem;
  letter-spacing: 1rem;
}

.countdown-number-label {
  font-size: 1rem;
}

.countdown-chevron {
  position: relative;
  font-size: 5rem;
  height: 0;
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
  cursor: pointer;
  transition: 0.3s color;
}
.countdown-chevron:hover {
  color: var(--green);
}

.countdown-title {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
}
.countdown-battle {
  color: var(--green);
}

.countdown-completed {
  padding-top: 1rem;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
}
.countdown-end {
  color: var(--green);
}

@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    top: 0.5rem;
  }
  50% {
    opacity: 0.5;
    top: 0rem;
  }
  100% {
    opacity: 1;
    top: 0.5rem;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    top: 0.5rem;
  }
  50% {
    opacity: 0.5;
    top: 0rem;
  }
  100% {
    opacity: 1;
    top: 0.5rem;
  }
}

@media screen and (min-width: 992px) {
  .countdown-number {
    width: 13rem;
  }
}
@media screen and (max-width: 991px) {
  .countdown {
    text-align: center;
  }
  .countdown-timer {
    flex-direction: column;
  }
  .countdown-timer > div {
    padding: 0;
  }
  .countdown-timer > div:nth-child(even) {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}

/* Hero-section main div */
.hero-section {
  height: 40rem;
  min-height: 100vh;
  padding: 5rem;
  background-image: url(/static/media/hero-bg.b20c015f.webp);
  background-image: linear-gradient(90deg, transparent 30%, black 80%),
    url(/static/media/hero-bg.b20c015f.webp);
  background-position: center;
  background-size: cover;
  color: white;
  overflow: hidden;
}

.hero-section .container,
.hero-section .row {
  height: 100%;
}

/* Text within hero div */
.hero-section .hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: left;
  font-weight: bold;
}

/* Hero content children*/
.hero-section .hero-content > * {
  padding: 1rem 0rem;
}

/* Hero Header */
.hero-section .hero-header {
  font-size: 3rem;
  font-weight: bold;
}

/* Highlighting key words in heading*/
.hero-section .hero-header .key-word {
  color: var(--green);
}

.hero-section .hero-header-wrapper {
  min-height: 13rem;
}

/* Text */
.hero-section .hero-desc {
  font-size: 1.5rem;
  font-weight: normal;
}

/* Hero Button */
.hero-button {
  background: transparent;
  position: relative;
  width: 14rem;
  height: 3rem;
  color: var(--green);
  transition: 0.3s;
}
.hero-button::before,
.hero-button::after {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  transition: width 0.3s, height 0.3s 0.3s;
}

.hero-button::before {
  top: -0.25rem;
  left: -0.25rem;
  border-top: 1px solid var(--green);
  border-left: 1px solid var(--green);
}

.hero-button::after {
  bottom: -0.25rem;
  right: -0.25rem;
  border-right: 1px solid var(--green);
  border-bottom: 1px solid var(--green);
}

.hero-button:hover::before,
.hero-button:hover::after {
  width: calc(100% + 0.5rem);
  height: calc(100% + 0.5rem);
}
.hero-section .hero-button:hover {
  background: var(--green);
  color: black;
}

/* Smaller screen size */
@media screen and (max-width: 991px) {
  .hero-section {
    background-image: linear-gradient(180deg, transparent 30%, black 90%),
      url(/static/media/hero-bg.b20c015f.webp);
    padding: 5rem 1rem;
    background-position: 30%;
  }
  .hero-section .hero-content {
    align-items: center;
    justify-content: flex-end;
  }
  .hero-section .hero-header-wrapper {
    min-height: 0.25rem;
  }
}

.badges {
  background: linear-gradient(rgba(0, 0, 0, 0.6), transparent 20%),
    url(/static/media/bg.46a75ee6.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  padding: 8rem 0rem;
}

.badges .section-title {
  color: rgb(48, 48, 48);
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
}

.badges-content {
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.badges-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.badges-description .title {
  color: var(--green);
  font-size: 2rem;
  font-weight: bold;
}
.badges-description .desc {
  font-size: 1.75rem;
}
.badges-select {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.badges-select::-webkit-scrollbar-track {
  background: transparent;
}

.badges-select-item {
  position: relative;
  top: 0;
  height: 7rem;
  width: 7rem;
  margin: 1rem;
  background-size: 100%;
  background-position: center;
  transition: 0.3s;
  flex-shrink: 0;
  cursor: pointer;
  background-repeat: no-repeat;
  -webkit-filter: drop-shadow(0rem 0.3rem 0.3rem rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0rem 0.3rem 0.3rem rgba(0, 0, 0, 0.5));
}
.badges-select-item.current {
  top: -0.5rem;
  -webkit-filter: drop-shadow(0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.8));
          filter: drop-shadow(0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.8));
}

.badges-select-item:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  -webkit-filter: drop-shadow(0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.8));
          filter: drop-shadow(0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.8));
}

/* Badge Preview */
.badges-preview {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20rem;
}

.badges-preview-img {
  height: 100%;
  width: 100%;
  margin: 0rem 2rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: 0.3s;
  -webkit-filter: drop-shadow(0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.8));
          filter: drop-shadow(0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.8));
}

.badges-group {
  position: relative;
}
.chevron-left,
.chevron-right {
  color: white;
  cursor: pointer;
  transition: 0.3s color;
  font-size: 5rem;
  width: 5rem;
  position: absolute;
  top: calc(50% - 2.5rem);
}

.chevron-left {
  left: 0;
}
.chevron-right {
  right: 0;
}
.chevron-left:hover,
.chevron-right:hover {
  color: rgb(68, 68, 68);
}

@media screen and (max-width: 991px) {
  .badges-description {
    text-align: center;
    padding-top: 3rem;
  }
  .badges-select {
    justify-content: left;
    overflow-x: scroll;
  }
}

.prizes {
  padding: 6rem 0rem;
  background: rgb(32, 98, 56);
  background: linear-gradient(
    130deg,
    rgba(32, 98, 56, 1) 0%,
    rgba(0, 34, 12, 1) 100%
  );
  color: white;
}

.prizes .section-title {
  width: 100%;
  text-align: center;
}

.prizes-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2rem;
  min-height: 30rem;
}
.prizes-card {
  width: 20rem;
  height: 20rem;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.prizes-front {
  position: relative;
  height: 20rem;
  background-color: rgb(34, 34, 34);
  border-radius: 1rem;
}

.prizes-cash {
  position: absolute;
  text-align: right;
  bottom: 1rem;
  right: 1rem;
  font-size: 2.5rem;
  font-weight: bold;
  transition: 0.3s;
  color: rgb(196, 196, 196);
}

.prizes-card,
.prizes-img-right,
.prizes-img-left {
  -webkit-filter: drop-shadow(0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.5));
}

.prizes-img-left {
  position: absolute;
  bottom: -1rem;
  z-index: 1;
  transition: 0.3s;
  width: 16rem;
  left: -1rem;
}

.prizes-img-right {
  position: absolute;
  width: 16rem;
  right: -3rem;
  bottom: -0.25rem;
  z-index: 1;
  transition: 0.3s;
}

.prizes-card-gold .prizes-img-left {
  left: -4rem;
  bottom: -0.5rem;
}

.prizes-position {
  font-size: 6rem;
  text-align: right;
  padding: 1rem;
  transition: 0.3s color;
}

.prizes-back {
  border-radius: 0rem 0rem 1rem 1rem;
  text-align: center;
  font-size: 1.25rem;
  overflow: hidden;
  height: 0rem;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.prizes-card-gold .prizes-back {
  background-color: rgb(109, 93, 0);
}
.prizes-card-silver .prizes-back {
  background-color: rgb(105, 105, 105);
}
.prizes-card-bronze .prizes-back {
  background-color: rgb(88, 61, 39);
}

.prizes-back p {
  position: relative;
  bottom: 0;
}

@media screen and (min-width: 992px) {
  .prizes {
    padding-top: 8rem;
  }
  .prizes-card:hover {
    -webkit-filter: drop-shadow(0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.8));
            filter: drop-shadow(0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.8));
  }
  .prizes-card:hover .prizes-front {
    border-radius: 1rem 1rem 0rem 0rem;
  }
  .prizes-card:hover .prizes-img-left {
    width: 16.25rem;
    left: -1.5rem;
    bottom: -1.25rem;
  }
  .prizes-card-gold:hover .prizes-img-left {
    width: 16.25rem;
    left: -4.5rem;
    bottom: -0.75rem;
  }
  .prizes-card:hover .prizes-img-right {
    width: 16.5rem;
    right: -3.5rem;
    bottom: -0.75rem;
  }
  .prizes-card:hover .prizes-cash {
    bottom: 0.75rem;
    right: 0.75rem;
  }

  .prizes-front:hover + .prizes-back,
  .prizes-back:hover {
    padding-top: 1rem;
    height: 8rem;
  }
  .prizes-card-gold:hover .prizes-position {
    color: rgb(248, 211, 0);
  }
  .prizes-card-silver:hover .prizes-position {
    color: rgb(180, 180, 180);
  }
  .prizes-card-bronze:hover .prizes-position {
    color: rgb(199, 143, 98);
  }

  .prizes-card-silver {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  .prizes-card-bronze {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }

  /* Card size on hover*/
  .prizes-card-gold:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }
  .prizes-card-silver:hover {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  .prizes-card-bronze:hover {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
}

@media screen and (max-width: 991px) {
  .prizes-cards {
    flex-direction: column;
    align-items: center;
  }
  .prizes-card {
    height: 30rem;
  }
  .prizes-back {
    padding-top: 1rem;
    height: 8rem;
  }
  .prizes-card-gold .prizes-position {
    color: rgb(248, 211, 0);
  }
  .prizes-card-silver .prizes-position {
    color: rgb(180, 180, 180);
  }
  .prizes-card-bronze .prizes-position {
    color: rgb(199, 143, 98);
  }
  .prizes-card-gold {
    order: 1;
  }
  .prizes-card-silver {
    order: 2;
  }
  .prizes-card-bronze {
    order: 3;
  }
}

.timeline {
  background-color: rgb(26, 26, 26);
  /* background: transparent; */
  padding: 6rem 0rem;
  overflow: hidden;
}

.timeline .section-title {
  color: white;
  width: 100%;
  text-align: center;
}

.timeline .date-light {
  color: var(--green);
}

/* Black icons */
.timeline svg {
  color: black;
}

/* Flipped icons (not on mobile) */
@media screen and (min-width: 1150px) {
  .timeline .flipped {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(0.25rem);
          backdrop-filter: blur(0.25rem);
}

.modal-container {
  position: relative;
  width: 60rem;
  max-width: calc(100% - 2rem);
  background-color: white;
  box-shadow: 0rem 0rem 5rem rgba(0, 0, 0, 0.5);
  padding: 4rem;
}

.modal-container.opening {
  -webkit-animation: reveal 0.5s forwards ease-out;
          animation: reveal 0.5s forwards ease-out;
}
.modal-container.closing {
  animation: reveal 0.3s reverse ease-out;
}

@-webkit-keyframes reveal {
  0% {
    opacity: 0;
    top: -5rem;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    top: -5rem;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

.modal-content {
  height: 20rem;
  overflow-y: auto;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 0rem;
  border-color: black;
}

.modal-content::-webkit-scrollbar-track {
  background: transparent;
}
.modal-content::-webkit-scrollbar-thumb {
  background-color: black;
}

.modal-content-heading {
  font-size: 1.75rem;
  font-weight: bold;
}

.modal .form-button-group {
  text-align: right;
  font-size: 1rem;
}

.register {
  padding: 8rem 0rem;
  background-color: white;
}

.register-row {
  display: flex;
}

.register-form {
  flex: 80% 1;
}

.register-image-wrapper {
  flex: 20% 1;
}

/* Form checkbox */
.form-checkbox {
  font-size: 1.25rem;
  padding: 2rem 0rem;
}

.form-checkbox-input {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
  cursor: pointer;
}

.form-checkbox-conduct {
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
}
.form-checkbox-conduct:hover {
  color: var(--green);
}

/* Form button */
.register .form-button {
  position: relative;
  margin-right: 2rem;
  background: transparent;
  width: 8rem;
  height: 3rem;
  color: black;
  transition: 0.3s;
}

/* Border around button */
.register .form-button::before,
.register .form-button::after {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  transition: 0.3s;
}

.register .form-button::before {
  top: -0.25rem;
  left: -0.25rem;
  border-left: 1px solid black;
  border-top: 1px solid black;
}

.register .form-button::after {
  bottom: -0.25rem;
  right: -0.25rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.register .form-submit::before,
.register .form-submit::after {
  transition: width 0.4s, height 0.4s 0.4s;
}
/* Disabled buttons */
.register .form-button:disabled::before {
  border-left: 1px solid grey;
  border-top: 1px solid grey;
}
.register .form-button:disabled:after {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.register .form-button:hover:not(:disabled)::before,
.register .form-button:hover:not(:disabled)::after {
  width: calc(100% + 0.5rem);
  height: calc(100% + 0.5rem);
}

/* Disabled hover when disabled */
.register .form-button:hover:not(:disabled) {
  background: black;
  color: var(--green);
}

.register .form-button:disabled {
  color: grey;
}

/* Register message */
.register .message {
  font-size: 1.25rem;
  padding-top: 0.5rem;
  height: 0;
}
.register .message.success {
  color: var(--green);
}
.register .message.error {
  color: red;
}

.register-image-wrapper {
  margin: 0;
  box-sizing: border-box;
  position: relative;
}
.register-image,
.register-image-hover {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.register-image {
  background-image: url(/static/media/egg.940528e4.webp);
}
.register-image-hover {
  opacity: 0;
  background-image: url(/static/media/egg-hover.e88d1d90.webp);
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s;
}
.register:hover .register-image-hover {
  opacity: 1;
}

/* When registrations are closed */
.register-closed {
  height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
}
.register-closed-word {
  color: var(--green);
  font-size: 4rem;
}

/* Mobile Screens */
@media screen and (max-width: 991px) {
  .register .form-button {
    margin-bottom: 0.5rem;
  }

  .register-row {
    flex-direction: column;
  }
  .register-form {
    width: 100%;
  }
  .register-image {
    display: none;
  }
}

.register .form-group {
  padding-top: 2rem;
  font-size: 1.25rem;
}
.register .form-group-title {
  font-size: 1.5rem;
}

/* Div surrounding sub-groups */
.register .form-sub-group-wrapper {
  display: flex;
  flex-direction: row;
}

.register .form-sub-group {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

.register .form-sub-group::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  background-color: var(--green);
  left: 0;
  bottom: 0;
  z-index: 1;
  transition: width 0.3s;
}
.register .form-sub-group:focus-within::after {
  width: 100%;
}

.register .form-sub-group .form-label {
  position: relative;
  top: 1.75rem;
  left: 0.5rem;
  order: 1;
  z-index: 0;
  transition: 0.3s;
  height: 1rem;
}

/* Hiding dummy placeholders */
.register .form-sub-group .form-control::-webkit-input-placeholder {
  opacity: 0;
}
.register .form-sub-group .form-control:-ms-input-placeholder {
  opacity: 0;
}
.register .form-sub-group .form-control::placeholder {
  opacity: 0;
}

.register .form-sub-group .form-control {
  position: relative;
  z-index: 1;
  order: 2;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  transition: 0.3s;
  border-bottom: 1px solid black;
  font-size: 1.25rem;
}

.register .form-sub-group .form-control:focus {
  box-shadow: none;
}
.register .form-sub-group .form-control:required + .form-label::after {
  content: "*";
  color: red;
}

/* Moves label if placeholder is not shown or if it is focused */
.register .form-sub-group .form-control:not(:-ms-input-placeholder) + .form-label {
  top: 0;
  left: 0;
}
.register .form-sub-group .form-control:not(:placeholder-shown) + .form-label,
.register .form-sub-group .form-control:focus + .form-label {
  top: 0;
  left: 0;
}

.register .form-sub-group .form-control:focus + .form-label {
  color: var(--green);
}

@media screen and (max-width: 991px) {
  .register .form-sub-group-wrapper {
    flex-direction: column;
  }
}

.footer {
  background-color: rgb(26, 26, 26);
  color: rgb(190, 190, 190);
  padding: 5rem 0rem;
}

.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem;
}

.footer-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo {
  padding: 3rem;
  height: 14rem;
}

.footer-text {
  text-align: center;
  padding: 3rem;
  transition: color 0.3s;
}

.footer:hover .footer-text {
  color: var(--green);
}

.socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1rem;
  padding: 0.5rem;
  order: 2;
}

.socials > div {
  margin: 0rem 0.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transition: 0.3s;
}
.footer-logo {
  width: 100%;
  height: auto;
}

.socials a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socials > div a {
  text-align: center;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 992px) {
  .socials a {
    color: grey;
  }
  .socials > div {
    border: 1px solid grey;
  }
  .socials > div:hover {
    border: 1px solid transparent;
  }
  .socials > div:hover a {
    color: black;
  }
  .socials-instagram:hover {
    background-color: var(--instagram);
    box-shadow: 0rem 0rem 1rem var(--instagram);
  }
  .socials-facebook:hover {
    background-color: var(--facebook);
    box-shadow: 0rem 0rem 1rem var(--facebook);
  }
  .socials-linkedin:hover {
    background-color: var(--linkedin);
    box-shadow: 0rem 0rem 1rem var(--linkedin);
  }
}

.footer-copyright {
  /* width: 100%; */
  text-align: center;
  padding-top: 2rem;
}

.footer-back-to-top {
  float: right;
  cursor: pointer;
  color: rgb(190, 190, 190);
  text-decoration: none;
  transition: color 0.3s;
}
.footer-back-to-top:hover {
  color: var(--green);
}

@media only screen and (max-width: 991px) {
  .footer-logo {
    padding: 0rem;
    vertical-align: center;
    margin: 0rem;
    width: 100%;
    height: auto;
  }

  .socials > div {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.25rem;
  }

  .socials-instagram {
    border: 1px solid var(--instagram);
  }
  .socials-facebook {
    border: 1px solid var(--facebook);
  }
  .socials-linkedin {
    border: 1px solid var(--linkedin);
  }

  .socials-instagram svg {
    color: var(--instagram);
  }
  .socials-facebook svg {
    color: var(--facebook);
  }
  .socials-linkedin svg {
    color: var(--linkedin);
  }
  .socials > div a {
    color: black;
  }
}

@font-face {
    font-family: 'Futura PT';
    src: url(/static/media/FuturaPT-Bold.c1c93dd4.eot);
    src: local('Futura PT Bold'), local('FuturaPT-Bold'),
        url(/static/media/FuturaPT-Bold.c1c93dd4.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-Bold.0679e922.woff2) format('woff2'),
        url(/static/media/FuturaPT-Bold.865ce097.woff) format('woff'),
        url(/static/media/FuturaPT-Bold.30a810fa.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url(/static/media/FuturaPT-Heavy.1bd843db.eot);
    src: local('Futura PT Heavy'), local('FuturaPT-Heavy'),
        url(/static/media/FuturaPT-Heavy.1bd843db.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-Heavy.3686f59e.woff2) format('woff2'),
        url(/static/media/FuturaPT-Heavy.32062419.woff) format('woff'),
        url(/static/media/FuturaPT-Heavy.cc2e37d3.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Demi';
    src: url(/static/media/FuturaPT-DemiObl.bfa63d8e.eot);
    src: local('Futura PT Demi Oblique'), local('FuturaPT-DemiObl'),
        url(/static/media/FuturaPT-DemiObl.bfa63d8e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-DemiObl.49f404e9.woff2) format('woff2'),
        url(/static/media/FuturaPT-DemiObl.f32ff1f2.woff) format('woff'),
        url(/static/media/FuturaPT-DemiObl.81bcbf3e.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond Extra';
    src: url(/static/media/FuturaPTCond-ExtraBoldObl.db5a77ca.eot);
    src: local('Futura PT Cond Extra Bold Oblique'), local('FuturaPTCond-ExtraBoldObl'),
        url(/static/media/FuturaPTCond-ExtraBoldObl.db5a77ca.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPTCond-ExtraBoldObl.e25913c2.woff2) format('woff2'),
        url(/static/media/FuturaPTCond-ExtraBoldObl.cc30826d.woff) format('woff'),
        url(/static/media/FuturaPTCond-ExtraBoldObl.81803d41.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond Book';
    src: url(/static/media/FuturaPTCond-Book.3657449c.eot);
    src: local('Futura PT Cond Book'), local('FuturaPTCond-Book'),
        url(/static/media/FuturaPTCond-Book.3657449c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPTCond-Book.6865fb32.woff2) format('woff2'),
        url(/static/media/FuturaPTCond-Book.c3b9a52c.woff) format('woff'),
        url(/static/media/FuturaPTCond-Book.6f802bf6.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url(/static/media/FuturaPT-LightObl.3b2c0fd0.eot);
    src: local('Futura PT Light Oblique'), local('FuturaPT-LightObl'),
        url(/static/media/FuturaPT-LightObl.3b2c0fd0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-LightObl.72b59126.woff2) format('woff2'),
        url(/static/media/FuturaPT-LightObl.a1ad2336.woff) format('woff'),
        url(/static/media/FuturaPT-LightObl.3ededb4d.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url(/static/media/FuturaPT-BookObl.9c4b23fb.eot);
    src: local('Futura PT Book Oblique'), local('FuturaPT-BookObl'),
        url(/static/media/FuturaPT-BookObl.9c4b23fb.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-BookObl.982d5c74.woff2) format('woff2'),
        url(/static/media/FuturaPT-BookObl.29512195.woff) format('woff'),
        url(/static/media/FuturaPT-BookObl.8625e188.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT';
    src: url(/static/media/FuturaPT-HeavyObl.995e6a39.eot);
    src: local('Futura PT Heavy Oblique'), local('FuturaPT-HeavyObl'),
        url(/static/media/FuturaPT-HeavyObl.995e6a39.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-HeavyObl.bd66252d.woff2) format('woff2'),
        url(/static/media/FuturaPT-HeavyObl.c09ad887.woff) format('woff'),
        url(/static/media/FuturaPT-HeavyObl.7418c6a2.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url(/static/media/FuturaPTCond-BoldObl.f05a64bc.eot);
    src: local('Futura PT Cond Bold Oblique'), local('FuturaPTCond-BoldObl'),
        url(/static/media/FuturaPTCond-BoldObl.f05a64bc.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPTCond-BoldObl.2571f2eb.woff2) format('woff2'),
        url(/static/media/FuturaPTCond-BoldObl.78e6411c.woff) format('woff'),
        url(/static/media/FuturaPTCond-BoldObl.8bb51575.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Demi';
    src: url(/static/media/FuturaPT-Demi.a31567d0.eot);
    src: local('Futura PT Demi'), local('FuturaPT-Demi'),
        url(/static/media/FuturaPT-Demi.a31567d0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-Demi.a56d557e.woff2) format('woff2'),
        url(/static/media/FuturaPT-Demi.483e48a3.woff) format('woff'),
        url(/static/media/FuturaPT-Demi.626ca5d4.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Cond Book';
    src: url(/static/media/FuturaPTCond-BookObl.8495f5fc.eot);
    src: local('Futura PT Cond Book Oblique'), local('FuturaPTCond-BookObl'),
        url(/static/media/FuturaPTCond-BookObl.8495f5fc.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPTCond-BookObl.96036d6c.woff2) format('woff2'),
        url(/static/media/FuturaPTCond-BookObl.d7e5e011.woff) format('woff'),
        url(/static/media/FuturaPTCond-BookObl.2cd80cc9.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Extra';
    src: url(/static/media/FuturaPT-ExtraBold.11fabf18.eot);
    src: local('Futura PT Extra Bold'), local('FuturaPT-ExtraBold'),
        url(/static/media/FuturaPT-ExtraBold.11fabf18.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-ExtraBold.8fbecb61.woff2) format('woff2'),
        url(/static/media/FuturaPT-ExtraBold.4304b245.woff) format('woff'),
        url(/static/media/FuturaPT-ExtraBold.3b1f0d98.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url(/static/media/FuturaPTCond-Medium.588fe7b6.eot);
    src: local('Futura PT Cond Medium'), local('FuturaPTCond-Medium'),
        url(/static/media/FuturaPTCond-Medium.588fe7b6.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPTCond-Medium.5adaa7d7.woff2) format('woff2'),
        url(/static/media/FuturaPTCond-Medium.9b4322d8.woff) format('woff'),
        url(/static/media/FuturaPTCond-Medium.00e4a220.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url(/static/media/FuturaPT-Medium.6546797a.eot);
    src: local('Futura PT Medium'), local('FuturaPT-Medium'),
        url(/static/media/FuturaPT-Medium.6546797a.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-Medium.a1c0db37.woff2) format('woff2'),
        url(/static/media/FuturaPT-Medium.c8b92be0.woff) format('woff'),
        url(/static/media/FuturaPT-Medium.4ab635fb.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Cond Extra';
    src: url(/static/media/FuturaPTCond-ExtraBold.5b7d31f7.eot);
    src: local('Futura PT Cond Extra Bold'), local('FuturaPTCond-ExtraBold'),
        url(/static/media/FuturaPTCond-ExtraBold.5b7d31f7.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPTCond-ExtraBold.a055a96e.woff2) format('woff2'),
        url(/static/media/FuturaPTCond-ExtraBold.1f4eb3d0.woff) format('woff'),
        url(/static/media/FuturaPTCond-ExtraBold.ec57e7c5.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url(/static/media/FuturaPT-MediumObl.378ead60.eot);
    src: local('Futura PT Medium Oblique'), local('FuturaPT-MediumObl'),
        url(/static/media/FuturaPT-MediumObl.378ead60.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-MediumObl.df6b36fd.woff2) format('woff2'),
        url(/static/media/FuturaPT-MediumObl.42673472.woff) format('woff'),
        url(/static/media/FuturaPT-MediumObl.21d08164.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url(/static/media/FuturaPTCond-Bold.8a1b641d.eot);
    src: local('Futura PT Cond Bold'), local('FuturaPTCond-Bold'),
        url(/static/media/FuturaPTCond-Bold.8a1b641d.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPTCond-Bold.bc0d3c2c.woff2) format('woff2'),
        url(/static/media/FuturaPTCond-Bold.284ef35d.woff) format('woff'),
        url(/static/media/FuturaPTCond-Bold.f8addf39.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url(/static/media/FuturaPT-BoldObl.9f7e599e.eot);
    src: local('Futura PT Bold Oblique'), local('FuturaPT-BoldObl'),
        url(/static/media/FuturaPT-BoldObl.9f7e599e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-BoldObl.d3083123.woff2) format('woff2'),
        url(/static/media/FuturaPT-BoldObl.bba37fd8.woff) format('woff'),
        url(/static/media/FuturaPT-BoldObl.d50a2015.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url(/static/media/FuturaPT-Book.55bc418a.eot);
    src: local('Futura PT Book'), local('FuturaPT-Book'),
        url(/static/media/FuturaPT-Book.55bc418a.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-Book.d11589b0.woff2) format('woff2'),
        url(/static/media/FuturaPT-Book.4b340965.woff) format('woff'),
        url(/static/media/FuturaPT-Book.16ebcf48.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url(/static/media/FuturaPT-Light.f24dc6ae.eot);
    src: local('Futura PT Light'), local('FuturaPT-Light'),
        url(/static/media/FuturaPT-Light.f24dc6ae.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-Light.fb1cd93e.woff2) format('woff2'),
        url(/static/media/FuturaPT-Light.bcdac18d.woff) format('woff'),
        url(/static/media/FuturaPT-Light.ba102592.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url(/static/media/FuturaPTCond-MediumObl.948ea46e.eot);
    src: local('Futura PT Cond Medium Oblique'), local('FuturaPTCond-MediumObl'),
        url(/static/media/FuturaPTCond-MediumObl.948ea46e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPTCond-MediumObl.a5d18458.woff2) format('woff2'),
        url(/static/media/FuturaPTCond-MediumObl.f61a3fb9.woff) format('woff'),
        url(/static/media/FuturaPTCond-MediumObl.042cb2cb.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Extra';
    src: url(/static/media/FuturaPT-ExtraBoldObl.14045158.eot);
    src: local('Futura PT Extra Bold Oblique'), local('FuturaPT-ExtraBoldObl'),
        url(/static/media/FuturaPT-ExtraBoldObl.14045158.eot?#iefix) format('embedded-opentype'),
        url(/static/media/FuturaPT-ExtraBoldObl.21c61f2a.woff2) format('woff2'),
        url(/static/media/FuturaPT-ExtraBoldObl.c6bdb41c.woff) format('woff'),
        url(/static/media/FuturaPT-ExtraBoldObl.4cd5ff49.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}


/* Colors */
:root {
  --green: #14db8c;
  --green-transparent: #14db8b91;

  /* Socials */
  --instagram: #f706ae;
  --facebook: #0350f5;
  --linkedin: #00b7ff;
}

/* Fonts */

* {
  vertical-align: baseline;
  font-weight: inherit;
  font-family: "Futura PT";
  font-style: inherit;
  font-size: 100%;
  border: 0 none;
  outline: 0;
  padding: 0;
  margin: 0;
}

/* Animate fade in */
.fade-in {
  -webkit-animation: fade-in 0.4s forwards;
          animation: fade-in 0.4s forwards;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

.section-title {
  font-weight: bold;
}

/* Scrollbar */
*::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.5rem;
}
*::-webkit-scrollbar-track {
  background: black;
}
*::-webkit-scrollbar-thumb {
  background-color: rgb(218, 218, 218);
}

@media screen and (max-width: 991px) {
  html {
    font-size: 12px;
  }
}

