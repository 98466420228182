.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(0.25rem);
}

.modal-container {
  position: relative;
  width: 60rem;
  max-width: calc(100% - 2rem);
  background-color: white;
  box-shadow: 0rem 0rem 5rem rgba(0, 0, 0, 0.5);
  padding: 4rem;
}

.modal-container.opening {
  animation: reveal 0.5s forwards ease-out;
}
.modal-container.closing {
  animation: reveal 0.3s reverse ease-out;
}

@keyframes reveal {
  0% {
    opacity: 0;
    top: -5rem;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

.modal-content {
  height: 20rem;
  overflow-y: auto;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 0rem;
  border-color: black;
}

.modal-content::-webkit-scrollbar-track {
  background: transparent;
}
.modal-content::-webkit-scrollbar-thumb {
  background-color: black;
}

.modal-content-heading {
  font-size: 1.75rem;
  font-weight: bold;
}

.modal .form-button-group {
  text-align: right;
  font-size: 1rem;
}
