/* Colors */
:root {
  --green: #14db8c;
  --green-transparent: #14db8b91;

  /* Socials */
  --instagram: #f706ae;
  --facebook: #0350f5;
  --linkedin: #00b7ff;
}

/* Fonts */
@import url("assets/fonts/FuturaPT/stylesheet.css");

* {
  vertical-align: baseline;
  font-weight: inherit;
  font-family: "Futura PT";
  font-style: inherit;
  font-size: 100%;
  border: 0 none;
  outline: 0;
  padding: 0;
  margin: 0;
}

/* Animate fade in */
.fade-in {
  animation: fade-in 0.4s forwards;
}
@keyframes fade-in {
  0% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

.section-title {
  font-weight: bold;
}

/* Scrollbar */
*::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.5rem;
}
*::-webkit-scrollbar-track {
  background: black;
}
*::-webkit-scrollbar-thumb {
  background-color: rgb(218, 218, 218);
}

@media screen and (max-width: 991px) {
  html {
    font-size: 12px;
  }
}
