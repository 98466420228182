.register {
  padding: 8rem 0rem;
  background-color: white;
}

.register-row {
  display: flex;
}

.register-form {
  flex: 80%;
}

.register-image-wrapper {
  flex: 20%;
}

/* Form checkbox */
.form-checkbox {
  font-size: 1.25rem;
  padding: 2rem 0rem;
}

.form-checkbox-input {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
  cursor: pointer;
}

.form-checkbox-conduct {
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
}
.form-checkbox-conduct:hover {
  color: var(--green);
}

/* Form button */
.register .form-button {
  position: relative;
  margin-right: 2rem;
  background: transparent;
  width: 8rem;
  height: 3rem;
  color: black;
  transition: 0.3s;
}

/* Border around button */
.register .form-button::before,
.register .form-button::after {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  transition: 0.3s;
}

.register .form-button::before {
  top: -0.25rem;
  left: -0.25rem;
  border-left: 1px solid black;
  border-top: 1px solid black;
}

.register .form-button::after {
  bottom: -0.25rem;
  right: -0.25rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.register .form-submit::before,
.register .form-submit::after {
  transition: width 0.4s, height 0.4s 0.4s;
}
/* Disabled buttons */
.register .form-button:disabled::before {
  border-left: 1px solid grey;
  border-top: 1px solid grey;
}
.register .form-button:disabled:after {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.register .form-button:hover:not(:disabled)::before,
.register .form-button:hover:not(:disabled)::after {
  width: calc(100% + 0.5rem);
  height: calc(100% + 0.5rem);
}

/* Disabled hover when disabled */
.register .form-button:hover:not(:disabled) {
  background: black;
  color: var(--green);
}

.register .form-button:disabled {
  color: grey;
}

/* Register message */
.register .message {
  font-size: 1.25rem;
  padding-top: 0.5rem;
  height: 0;
}
.register .message.success {
  color: var(--green);
}
.register .message.error {
  color: red;
}

.register-image-wrapper {
  margin: 0;
  box-sizing: border-box;
  position: relative;
}
.register-image,
.register-image-hover {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.register-image {
  background-image: url("./assets/egg.webp");
}
.register-image-hover {
  opacity: 0;
  background-image: url("./assets/egg-hover.webp");
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s;
}
.register:hover .register-image-hover {
  opacity: 1;
}

/* When registrations are closed */
.register-closed {
  height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
}
.register-closed-word {
  color: var(--green);
  font-size: 4rem;
}

/* Mobile Screens */
@media screen and (max-width: 991px) {
  .register .form-button {
    margin-bottom: 0.5rem;
  }

  .register-row {
    flex-direction: column;
  }
  .register-form {
    width: 100%;
  }
  .register-image {
    display: none;
  }
}
