/* Navigation bar */
.navbar {
  font-size: 1rem;
  font-weight: bold;
  position: fixed;
  width: 100%;
  height: 5rem;
  z-index: 3;
  padding: 0.5rem 4rem;
  transition: 0.3s background-color;
}

.navbar-logo,
.navbar-logo-full {
  height: 4rem;
  cursor: pointer;
}

/* Only for larger screens */
@media screen and (min-width: 992px) {
  .navbar {
    backdrop-filter: blur(0.5rem);
  }
  /* Branding */
  .navbar-logo {
    position: relative;
    transition: 0.3s;
  }
  .navbar-logo-full {
    position: absolute;
    transition: 0.3s;
  }

  /* Logo animations */
  .navbar-light .navbar-logo-full {
    left: 4rem;
    opacity: 1;
  }

  .navbar-dark .navbar-logo-full {
    left: 3rem;
    opacity: 0;
  }

  .navbar-dark .navbar-logo {
    opacity: 1;
    left: 0;
  }

  .navbar-light .navbar-logo {
    left: 1.1rem;
    opacity: 0;
  }
}

/* Navbar Background color */
.navbar.navbar-light.bg-light {
  background-color: transparent !important;
}

.navbar.navbar-dark.bg-dark {
  background-color: rgba(22, 25, 27, 0.8) !important;
  box-shadow: 0rem 0.5rem 2rem rgba(0, 0, 0, 0.7);
}

/* Navlist Items */
.navbar .navbar-nav .nav-item .nav-link {
  padding: 0rem 3rem;
  color: white;
  cursor: pointer;
}

.navbar .navbar-nav .nav-item .nav-link.current,
.navbar .navbar-nav .nav-item .nav-link:hover {
  color: var(--green);
}

/* Navlist toggle */
.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("../../assets/images/logo.webp");
}

.navbar .navbar-toggler .navbar-toggler-icon::after {
  content: url("./assets/up-arrow.svg");
  width: 100%;
  height: 100%;
}

/* Mobile */
@media screen and (max-width: 991px) {
  .navbar.navbar-dark.bg-dark {
    background-color: black !important;
  }

  /* Hide logo */
  .navbar .navbar-logo,
  .navbar .navbar-logo-full {
    display: none;
  }
  /* Div with links */
  .navbar .navbar-collapse {
    position: fixed;
    top: 0;
    padding: 6rem 1rem;
    background-color: rgba(0, 0, 0, 0.95);
    height: 100vh;
    min-width: 33vw;
    right: -100%;
    text-align: right;
    transition: 0.1s right;
  }
  .navbar .navbar-collapse.show {
    right: 0;
    z-index: -1;
  }
  .navbar .navbar-nav .nav-item {
    padding: 1rem 0rem;
  }
  .navbar .navbar-nav > *:not(:last-child) {
    border-bottom: 1px solid white;
  }

  /* Navlis toggle */
  .navbar .navbar-toggler {
    border: none;
    box-shadow: none;
  }
  .navbar .navbar-toggler .navbar-toggler-icon {
    background-size: cover;
    height: 2rem;
  }
  .navbar .navbar-toggler .navbar-toggler-icon::after {
    display: block;
    position: relative;
    right: 3rem;
    transform: rotate(90deg);
    filter: invert(56%) sepia(54%) saturate(561%) hue-rotate(104deg)
      brightness(89%) contrast(94%);
    transition: 0.3s;
  }
  .navbar .navbar-toggler.collapsed .navbar-toggler-icon::after {
    transform: rotate(270deg);
    filter: invert(100%);
  }
}
