.badges {
  background: linear-gradient(rgba(0, 0, 0, 0.6), transparent 20%),
    url("assets/bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  padding: 8rem 0rem;
}

.badges .section-title {
  color: rgb(48, 48, 48);
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
}

.badges-content {
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.badges-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.badges-description .title {
  color: var(--green);
  font-size: 2rem;
  font-weight: bold;
}
.badges-description .desc {
  font-size: 1.75rem;
}
.badges-select {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.badges-select::-webkit-scrollbar-track {
  background: transparent;
}

.badges-select-item {
  position: relative;
  top: 0;
  height: 7rem;
  width: 7rem;
  margin: 1rem;
  background-size: 100%;
  background-position: center;
  transition: 0.3s;
  flex-shrink: 0;
  cursor: pointer;
  background-repeat: no-repeat;
  filter: drop-shadow(0rem 0.3rem 0.3rem rgba(0, 0, 0, 0.5));
}
.badges-select-item.current {
  top: -0.5rem;
  filter: drop-shadow(0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.8));
}

.badges-select-item:hover {
  transform: scale(1.02);
  filter: drop-shadow(0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.8));
}

/* Badge Preview */
.badges-preview {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20rem;
}

.badges-preview-img {
  height: 100%;
  width: 100%;
  margin: 0rem 2rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: 0.3s;
  filter: drop-shadow(0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.8));
}

.badges-group {
  position: relative;
}
.chevron-left,
.chevron-right {
  color: white;
  cursor: pointer;
  transition: 0.3s color;
  font-size: 5rem;
  width: 5rem;
  position: absolute;
  top: calc(50% - 2.5rem);
}

.chevron-left {
  left: 0;
}
.chevron-right {
  right: 0;
}
.chevron-left:hover,
.chevron-right:hover {
  color: rgb(68, 68, 68);
}

@media screen and (max-width: 991px) {
  .badges-description {
    text-align: center;
    padding-top: 3rem;
  }
  .badges-select {
    justify-content: left;
    overflow-x: scroll;
  }
}
