.footer {
  background-color: rgb(26, 26, 26);
  color: rgb(190, 190, 190);
  padding: 5rem 0rem;
}

.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem;
}

.footer-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo {
  padding: 3rem;
  height: 14rem;
}

.footer-text {
  text-align: center;
  padding: 3rem;
  transition: color 0.3s;
}

.footer:hover .footer-text {
  color: var(--green);
}

.socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1rem;
  padding: 0.5rem;
  order: 2;
}

.socials > div {
  margin: 0rem 0.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transition: 0.3s;
}
.footer-logo {
  width: 100%;
  height: auto;
}

.socials a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socials > div a {
  text-align: center;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 992px) {
  .socials a {
    color: grey;
  }
  .socials > div {
    border: 1px solid grey;
  }
  .socials > div:hover {
    border: 1px solid transparent;
  }
  .socials > div:hover a {
    color: black;
  }
  .socials-instagram:hover {
    background-color: var(--instagram);
    box-shadow: 0rem 0rem 1rem var(--instagram);
  }
  .socials-facebook:hover {
    background-color: var(--facebook);
    box-shadow: 0rem 0rem 1rem var(--facebook);
  }
  .socials-linkedin:hover {
    background-color: var(--linkedin);
    box-shadow: 0rem 0rem 1rem var(--linkedin);
  }
}

.footer-copyright {
  /* width: 100%; */
  text-align: center;
  padding-top: 2rem;
}

.footer-back-to-top {
  float: right;
  cursor: pointer;
  color: rgb(190, 190, 190);
  text-decoration: none;
  transition: color 0.3s;
}
.footer-back-to-top:hover {
  color: var(--green);
}

@media only screen and (max-width: 991px) {
  .footer-logo {
    padding: 0rem;
    vertical-align: center;
    margin: 0rem;
    width: 100%;
    height: auto;
  }

  .socials > div {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.25rem;
  }

  .socials-instagram {
    border: 1px solid var(--instagram);
  }
  .socials-facebook {
    border: 1px solid var(--facebook);
  }
  .socials-linkedin {
    border: 1px solid var(--linkedin);
  }

  .socials-instagram svg {
    color: var(--instagram);
  }
  .socials-facebook svg {
    color: var(--facebook);
  }
  .socials-linkedin svg {
    color: var(--linkedin);
  }
  .socials > div a {
    color: black;
  }
}
