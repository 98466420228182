.countdown {
  height: 40rem;
  min-height: 100vh;
  background-image: url("assets/bg.webp");
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgb(0, 0, 0)),
    url("assets/bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  font-size: 1rem;
}

.countdown > .container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.countdown-timer {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  text-align: center;
}

.countdown-timer > * {
  padding: 1rem;
  font-size: 1rem;
}

.countdown-number {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.countdown-number-num {
  font-size: 5rem;
  letter-spacing: 1rem;
}

.countdown-number-label {
  font-size: 1rem;
}

.countdown-chevron {
  position: relative;
  font-size: 5rem;
  height: 0;
  animation: pulse 2s infinite;
  cursor: pointer;
  transition: 0.3s color;
}
.countdown-chevron:hover {
  color: var(--green);
}

.countdown-title {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
}
.countdown-battle {
  color: var(--green);
}

.countdown-completed {
  padding-top: 1rem;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
}
.countdown-end {
  color: var(--green);
}

@keyframes pulse {
  0% {
    opacity: 1;
    top: 0.5rem;
  }
  50% {
    opacity: 0.5;
    top: 0rem;
  }
  100% {
    opacity: 1;
    top: 0.5rem;
  }
}

@media screen and (min-width: 992px) {
  .countdown-number {
    width: 13rem;
  }
}
@media screen and (max-width: 991px) {
  .countdown {
    text-align: center;
  }
  .countdown-timer {
    flex-direction: column;
  }
  .countdown-timer > div {
    padding: 0;
  }
  .countdown-timer > div:nth-child(even) {
    transform: rotate(90deg);
  }
}
