/* Hero-section main div */
.hero-section {
  height: 40rem;
  min-height: 100vh;
  padding: 5rem;
  background-image: url("./assets/hero-bg.webp");
  background-image: linear-gradient(90deg, transparent 30%, black 80%),
    url("./assets/hero-bg.webp");
  background-position: center;
  background-size: cover;
  color: white;
  overflow: hidden;
}

.hero-section .container,
.hero-section .row {
  height: 100%;
}

/* Text within hero div */
.hero-section .hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: left;
  font-weight: bold;
}

/* Hero content children*/
.hero-section .hero-content > * {
  padding: 1rem 0rem;
}

/* Hero Header */
.hero-section .hero-header {
  font-size: 3rem;
  font-weight: bold;
}

/* Highlighting key words in heading*/
.hero-section .hero-header .key-word {
  color: var(--green);
}

.hero-section .hero-header-wrapper {
  min-height: 13rem;
}

/* Text */
.hero-section .hero-desc {
  font-size: 1.5rem;
  font-weight: normal;
}

/* Hero Button */
.hero-button {
  background: transparent;
  position: relative;
  width: 14rem;
  height: 3rem;
  color: var(--green);
  transition: 0.3s;
}
.hero-button::before,
.hero-button::after {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  transition: width 0.3s, height 0.3s 0.3s;
}

.hero-button::before {
  top: -0.25rem;
  left: -0.25rem;
  border-top: 1px solid var(--green);
  border-left: 1px solid var(--green);
}

.hero-button::after {
  bottom: -0.25rem;
  right: -0.25rem;
  border-right: 1px solid var(--green);
  border-bottom: 1px solid var(--green);
}

.hero-button:hover::before,
.hero-button:hover::after {
  width: calc(100% + 0.5rem);
  height: calc(100% + 0.5rem);
}
.hero-section .hero-button:hover {
  background: var(--green);
  color: black;
}

/* Smaller screen size */
@media screen and (max-width: 991px) {
  .hero-section {
    background-image: linear-gradient(180deg, transparent 30%, black 90%),
      url("./assets/hero-bg.webp");
    padding: 5rem 1rem;
    background-position: 30%;
  }
  .hero-section .hero-content {
    align-items: center;
    justify-content: flex-end;
  }
  .hero-section .hero-header-wrapper {
    min-height: 0.25rem;
  }
}
